<template scoped>
  <Panel-CRUD
    ref="crud"
    :endpoint="endpoint"
    :toPage="toPage"
    :resourceServer="resourceServer"
    :resourceId="resourceId"
    title="Manufactory"
    :create="create"
    :resourceServerApi="false"
    apiPath="Backend/Manufactory"
  >
    <template>
      <el-table-column prop="name" label="Name"> </el-table-column>
      <el-table-column prop="enable" label="Live Mode">
        <template slot-scope="scope">
          <el-switch
            @change="$refs.crud.setEnable(scope.row)"
            v-model="scope.row.enable"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="action" label="Action">
        <template slot-scope="scope">
          <el-row>
            <el-col :span="24">
              <el-tooltip
                class="item"
                effect="dark"
                content="Edit"
                placement="right"
              >
                <el-button
                  @click="edit(scope.row)"
                  icon="el-icon-edit"
                  circle
                ></el-button>
              </el-tooltip>
            </el-col>
            <el-col :span="24" :style="{ marginTop: '10px' }">
              <el-tooltip
                class="item"
                effect="dark"
                content="Product"
                placement="right"
              >
                <el-button
                  @click="product(scope.row)"
                  icon="el-icon-guide"
                  circle
                ></el-button>
              </el-tooltip>
            </el-col>
            <el-col :span="24" :style="{ marginTop: '10px' }">
              <el-tooltip
                class="item"
                effect="dark"
                content="Copy Id"
                placement="right"
              >
                <el-button
                  @click="$refs.crud.copyId(scope.row)"
                  icon="el-icon-document-copy"
                  circle
                ></el-button>
              </el-tooltip>
            </el-col>
            <el-col :span="24" :style="{ marginTop: '10px' }">
              <el-tooltip
                class="item"
                effect="dark"
                content="Delete"
                placement="right"
              >
                <el-button
                  type="danger"
                  @click="$refs.crud.remove(scope.row)"
                  icon="el-icon-delete"
                  circle
                ></el-button>
              </el-tooltip>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </template>
  </Panel-CRUD>
</template>
<script>
export default {
  props: {
    endpoint: String,
    toPage: Function,
    resourceServer: String,
    resourceId: String,
  },
  components: {
    "Panel-CRUD": () => import("cms_infrastructure_web/src/sdk/Admin/CRUD/CRUD-List.vue"),
    //"Panel-CRUD": () => import("./CRUD-List.vue"),
  },
  methods: {
    edit: function (item) {
      this.toPage(
        "components/Manufactory/Portal-Manufactory-CreateUpdate.vue",
        {
          id: item.id,
        }
      );
    },
    create: function () {
      this.toPage("components/Manufactory/Portal-Manufactory-CreateUpdate.vue");
    },
    product: function (item) {
      this.toPage("components/Product/Portal-Product.vue", {
        filter: [
          {
            id: "manufactoryId",
            name: "ManufactoryId",
            value: item.id,
            friendlyValue: item.name,
          },
        ],
      });
    },
  },
};
</script>